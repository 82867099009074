import React from "react";
import {
  useQuery,
  useMutation,
} from "@apollo/client";
import Main from "../components/main";
import {getCurrentUserQuery, loginMutation} from "../components/logic/user";
import Layout from "../components/section/layout";

export default function Index(props) {
  return (<Main>
    <Layout>
      <UserCheck {...props} />
    </Layout>
  </Main>);
}

function UserCheck(props) {
  const currentUserQuery = useQuery(getCurrentUserQuery);
  const [logout] = useMutation(loginMutation);
  if (currentUserQuery.loading) {
    // return <Loader />;
    return (<React.Fragment/>);
  }

  const user = currentUserQuery?.data?.classMethods?.User?.getCurrentUser;


  if (!user) {
    window.location.href = "/";
    return undefined;
  }
  return logout();
}
